<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('recharge.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <van-cell style="color: #cccccc"
              size="large"
              :title="$t('recharge.default[0]')"
              center
              to="/user/mixrecharge"
              is-link
      />
<!--      <van-cell-->
<!--              size="large"-->
<!--              :title="$t('recharge.default[1]')"-->
<!--              center-->
<!--              to="/user/prerecharge"-->
<!--              is-link-->
<!--      />-->
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import QRCode from "qrcodejs2"
  import Html2Canvas from "html2canvas"
export default {
  name: 'Recharge',
  components: {

  },
  props: ['rechargeId'],
  data() {
    return {


    }
  },
  computed: {

  },
  watch: {
    $route(){

    },
  },
  created() {
    this.$parent.navBarTitle = ''
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    gobindcoin() {
      this.$router.push("/user/bankCard");

    }
  }
}
</script>
<style scoped>
  .PageBox {
    color: #635327;
    background-color: #0F4F4E;
  }

  .van-nav-bar {
    background-color: #0F4F4E;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#cccccc;
  }
  .van-nav-bar>>>.van-nav-bar__arrow {
    color:#B5B5B5;
  }

.RechargeList{
  margin-bottom: 20px;
}
.RechargeList .van-cell__title{
  color: #FFFEFF;
  line-height: 1;
}
.RechargeList .van-cell__label{
  color: #FFFEFF;
}
.RechargeInfo .van-cell>>>.van-field__control{
  text-align: right;
}
.RechargeInfo .van-cell--large>>>.van-field__control{
  font-size:16px;
}
.RechargeInfo .van-cell .van-cell__value em{
  font-size: 18px;
  color: #4087f1;
}
.RechargeInfo .van-panel{
  margin: 10px;
  border: 1px rgba(255, 255,255,.2) solid;
  border-radius: 3px;
  background-color: #151d31;
}
.RechargeInfo .van-panel__header{
  background-color: #242d41;
  color: #fff;
}
.RechargeInfo .van-panel__content .van-cell{
  padding: 0 16px;
  margin: 10px 0;
}
.RechargeInfo .van-panel__content>>>.van-field__control{
  text-align: left;
}
.RechargeInfo .van-panel__content img{
  width: 80%;
  margin: 0 auto;
  display: block;
}
.van-divider{
  font-size: 12px;
  line-height: 1.5;
}
.MoneyList{
  word-break: break-word;
}
.MoneyList .van-cell__value{
  flex: auto;
}
.MoneyList .van-radio--horizontal{
  margin: 3px 6px 3px 0;
  position: relative;
}
.MoneyList .van-radio-group>>>.van-radio__icon{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.MoneyList .van-radio-group>>>.van-radio__icon--checked~.van-radio__label{
  color: #fff;
  padding: 0 17px 0 3px;
}
.MoneyList .van-radio-group>>>.van-radio__icon .van-icon{
  width: 100%;
  border-radius: 5px;
  text-align: right;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
}
.MoneyList .van-radio-group>>>.van-radio__label{
  margin: 0;
  position: relative;
  z-index: 9;
  padding: 0 10px;
  line-height: 28px;
}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image{
  width: 60px;
  height: 60px;
}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview{
  margin: 4px 8px 4px 0;
  background-color: #f7f8fa;
  border-radius: 8px;
}
.PageBox .bgc {
  width: 100%;
  /*background-color: #F46926;*/
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.ScrollBox {
  background-color: #0F4F4E;
}

.van-cell {
  background: #191C23;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #FFFEFF;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
</style>
